/*******************************************************************************
*  Index
*******************************************************************************
*/
$(document).ready(function(){

 validaContato();
 validaOrcamento();


  //Dont Drag Images
  $('img').on('dragstart', function(event) { event.preventDefault(); });

  //Fill Images into block, support IE 6
  $(".imgLiquidFillDefault").imgLiquid({
  fill: true,
  horizontalAlign: "center",
  verticalAlign: "center"
  });

  $('#slide').slick({
    arrows: true,
    prevArrow: '<div class="snoop-btn left"></div>',
    nextArrow: '<div class="snoop-btn right"></div>',
    infinite: true,
    speed: 800,
    fade: true,
    cssEase: 'linear'

  });

  $('#header #menu').slicknav({
    duration: 400,
    prependTo: "#header .menu-responsivo",
    init: function() {
      // $("#header .slicknav_menutxt").html("<i class='fa fa-bars'></i>");
      $("#header .slicknav_icon").html("<span class='slicknav_icon-bar first'></span><span class='slicknav_icon-bar second'></span><span class='slicknav_icon-bar third'></span>")
    },
    beforeOpen: function(){
      $("#header .slicknav_btn").append("<span class='slicknav_ghost'></span>");
    },
    beforeClose: function(){
        $("#header .slicknav_ghost").css('display', 'none');
    }

  });

  var content = $('#header .slicknav_nav').html();
  $('#header .slicknav_nav').html("");
  $('#header .slicknav_nav').prepend("<div class='mininav'></div>");
  $('#header .slicknav_nav .mininav').html(content);

  $("<button type='button' class='overlay-close'>Close</button>").prependTo('#header .slicknav_nav');

  $('#header .overlay-close').click(function(event) {
    $('#header #menu').slicknav('close');
  });
  $('#header .slicknav_nav li a').click(function(event) {
    $('#header #menu').slicknav('close');
  });

/*******************************************************************************
*  Página Produtos
********************************************************************************/

if(document.getElementById('paginaProdutos')){
  function check_action_button() {
    var width_item = $(window).width();
    width = parseInt(width_item);
    if(width <= 767){

    }
    else{
      $('.categorias #demo3 .slicknav_btn').removeClass('slicknav_collapsed');
      $('.categorias #demo3 .slicknav_btn').addClass('slicknav_open');

      $('.categorias #demo3 .slicknav_nav').removeClass('slicknav_hidden');
      $('.categorias #demo3 .slicknav_nav').css('display', '');
    }
  }

  $('#categorias').slicknav({
    label: 'Categorias',
    duration: 600,
    duplicate: false,
    prependTo:'#demo3',
    allowParentLinks: true,
    closedSymbol: "<i class='fa fa-plus' aria-hidden='true'></i>",
    openedSymbol: "<i class='fa fa-minus' aria-hidden='true'></i>",
    init: function(){
      check_action_button();
    },
  });
}

/*******************************************************************************
*  Página Detalhes
********************************************************************************/

if(document.getElementById('paginaDetalhes')){


   //Listagem da galeria
    var sync1 = $("#sync1");
    var sync2 = $("#sync2");

    sync1.owlCarousel({
      singleItem : true,
      slideSpeed : 500,
      navigation: false,
      pagination:false,
      afterAction : syncPosition,
      responsiveRefreshRate : 200,
    });
    sync2.owlCarousel({
      items : 5,
      itemsDesktop      : [1199,5],
      itemsDesktopSmall     : [979,5],
      itemsTablet       : [768,5],
      itemsMobile       : [479,3],
      pagination:false,
      responsiveRefreshRate : 100,
      center: true,
      afterInit : function(el){
        el.find(".owl-item").eq(0).addClass("synced");
      }
    });
    function syncPosition(el){
      var current = this.currentItem;
      $("#sync2")
      .find(".owl-item")
      .removeClass("synced")
      .eq(current)
      .addClass("synced")
      if($("#sync2").data("owlCarousel") !== undefined){
        center(current)
      }
    }
    $("#sync2").on("click", ".owl-item", function(e){
      e.preventDefault();
      var number = $(this).data("owlItem");
      sync1.trigger("owl.goTo",number);
    });
    function center(number){
      var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
      var num = number;
      var found = false;
      for(var i in sync2visible){
        if(num === sync2visible[i]){
          var found = true;
        }
      }
      if(found===false){
        if(num>sync2visible[sync2visible.length-1]){
          sync2.trigger("owl.goTo", num - sync2visible.length+2)
        }else{
          if(num - 1 === -1){
            num = 0;
          }
          sync2.trigger("owl.goTo", num);
        }
      } else if(num === sync2visible[sync2visible.length-1]){
        sync2.trigger("owl.goTo", sync2visible[1])
      } else if(num === sync2visible[0]){
        sync2.trigger("owl.goTo", num-1)
      }
    }

    //Zoom Image item Hover
    if($(window).width() >= 800){
      $(".zoomIn").each(function(e) {
        $(this).ezPlus({
          responsive: true,
          cursor: "crosshair",
          easing: false,
          borderSize: 0,
          //zoomType: 'inner',
          cursor: 'crosshair',
          zoomWindowFadeIn: 300,
          zoomWindowFadeOut: 300,
          lensFadeIn: 300,
          lensFadeOut: 300,
        //scrollZoom: true,
        //zoomType: 'lens',
        //lensShape: 'round',
        //lensSize: 200
      });
      });
    }else{
      $(".zoomIn").each(function(e) {
        $(this).ezPlus({
          responsive: true,
          cursor: "crosshair",
          easing: false,
          borderSize: 0,
          zoomType: 'inner',
          cursor: 'crosshair',
          zoomWindowFadeIn: 300,
          zoomWindowFadeOut: 300,
          lensFadeIn: 300,
          lensFadeOut: 300,
        //scrollZoom: true,
        //zoomType: 'lens',
        //lensShape: 'round',
        //lensSize: 200
      });
      });
    }
}



	//A link href smooth
	$('a[href*=#]:not([href=#])').click(function(event) {
    //Melhorar o script pois ele não aceita realizar o scroll em uma página interna por conta de algo na condição abaixo
		//if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html,body').animate({
					//I put the size of the fixed here
					scrollTop: (target.offset().top)-40
				}, 900);
				return false;
			}
		//}
	});


  //Mascaras de telefone
  $('#frmContatoTopo #telefone, #frmContatoFooter #telefone').mask('(99) 9999-9999?9');

  //Aplicando mascaras
  jQuery(function($) {
    $.mask.definitions['~']='[+-]';
      //Inicio Mascara Telefone
      $('#frmContatoTopo #telefone, #frmContatoFooter #telefone').focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
          element.mask("(99) 99999-999?9");
        } else {
          element.mask("(99) 9999-9999?9");
        }
      }).trigger('focusout');
    });
});


/*******************************************************************************
*  Home
********************************************************************************/
if(document.getElementById('home')){
  $(document).ready(function(){
  });
}

/*******************************************************************************
*  Banner
********************************************************************************/
if(document.getElementById('banner')){
  $(document).ready(function(){
    $('#banner .slide-banner').slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 700,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
      //cssEase: 'linear',
      cssEase: 'ease-out',
      pauseOnFocus: false,
      pauseOnHover: false,
    });
  });
}

/*******************************************************************************
*  footer
********************************************************************************/

/*******************************************************************************
*  Fale Conosco
********************************************************************************/
function BuscaCidades()
{
  $.ajax({
    type: "POST",
    url: "ajax/busca_cidades_valida.php",
    data: "uf=" + $("#estado").val(),
    success: function(resposta)
    {
      $("#Cidades").html(resposta);
    },
  });
}


jQuery(document).ready(function(){
$('#frmOrcamento #telefone, #frmCadastro #telefone').mask('(99) 9999-9999?9');

  //Aplicando mascaras
  jQuery(function($) {
    $.mask.definitions['~']='[+-]';
    //Inicio Mascara Telefone
    $('#frmContato #telefone, #frmOrcamento #telefone').focusout(function(){
      var phone, element;
      element = $(this);
      element.unmask();
      phone = element.val().replace(/\D/g, '');
      if(phone.length > 10) {
        element.mask("(99) 99999-999?9");
      } else {
        element.mask("(99) 9999-9999?9");
      }
    }).trigger('focusout');
  });
});

if(document.getElementById('footer')){
  $(document).ready(function() {
    //Create accordion in footer.
    function createAccordion(){
      //Verify if foundation exist to create an instance
      if (!$('footer .accordion').data('zfPlugin')) {
        $('footer .accordion').foundation();
      }
      var $elem = new Foundation.Accordion($('footer .accordion'), 'data-allow-all-closed: true');
      $('footer .accordion').foundation('up', $('footer .accordion-content'));
    }
    //Destroy accordion in footer.
    function destroyAccordion(){
      //Let it active before destroy
      $('footer .accordion .accordion-item').addClass("is-active");
      //Verify if foundation accordion exist
      if ($('footer .accordion').data('zfPlugin')) {
        $('footer .accordion').foundation('destroy');
      }
      //Clean css inline of accordion and Make it open in large screen after destroy
      $('footer .accordion .accordion-content').attr('style', 'display:block;');
    }
    //Check if is small or medium to keep closed.
    if(Foundation.MediaQuery.current == 'medium' || Foundation.MediaQuery.current == 'small'){
      createAccordion();
      $('footer .accordion').foundation('up', $('footer .accordion-content'));
    }
    //Destroy if larger or bigger
    if (Foundation.MediaQuery.atLeast('large')) {
      destroyAccordion();
    }
    //Watch if screen change to create or destroy elements.
    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
      if(newSize == "small" || newSize == "medium"){
        createAccordion();
      }else{
        //Bug because it doesn't know if is initialized already.
        destroyAccordion();
      }
    });
  });
}



/*******************************************************************************
*  Foundation Call
********************************************************************************/
$(document).foundation();

