

//CONFIGURAÇÕE DO VALIDADOR JQUERY

//Mundando o nome da classe de erro
//FIM
//Função para notificão de mensagens
function showMessage(type, text)
{
	$.noty.clearQueue();

	var n = noty({
		text        : text,
		type        : type,
		dismissQueue: false,
		layout      : 'topCenter',
		closeWith   : ['click'],
		timeout     : 3000,
		killer  : true,
		theme       : 'relax',
		animation   : {
			open  : 'animated fadeInDown',
			close : 'animated fadeOutUp',
			easing: 'swing',
			speed : 50
		}
	});
}

//Validação e envio do formulário da Home
function errosForm(form)
{
	valid = $("#"+form).valid();

	if(!valid)
	{
		messageError = "";

		$("#"+form+" label.error").each(function( index ) {

			if(messageError == "")
			{
				if($(this).html() != "")
				{
					messageError = " - " + $(this).html();
				}

			}
			else
			{
				if($(this).html() != "")
				{

					messageError = messageError + "<br />" + " - " + $(this).html()
				}
			}
		});

		showMessage("error", messageError);


	}


}


//Validação e envio do formulário de Contato
function validaContato()
{
    $("#frmContato").validate({
        rules:
        {
            nome:{required: true},
            cidade:{required: true},
			email: { required: true, email: true},
			telefone:{required: true},
			midia:{required: true},
			mensagem:{required: true},
        },
		messages: {
			nome: { required: 'Informe o seu nome', minlength: 'No mínimo 5 letras' },
			cidade: { required: 'Informe a sua cidade'},
			email: { required: 'Informe o seu e-mail', email: 'E-mail inválido, verifique.'},
			telefone:{ required:  'Informe o seu telefone'},
			mensagem:{ required:  'Informe a mensagem'},
			midia:{required: 'Informe onde nos conheceu'},
		},
		submitHandler: function( form ){
			var dados = $(form).serialize();

			$.ajax({
				type: "POST",
				url: "envia-contato.php",
				data: dados,
				success: function( data )
				{
					//alert(data);
					//Mostra a Msg no topo
					showMessage("success", "Contato enviado com sucesso!");
					goog_report_conversion ();

					//Reseta o Form
					$(form).each (function(){
					  this.reset();
					});
				}
			});
		}
    });
}

//Validação e envio do formulário de Contato
function validaOrcamento()
{
    $("#frmOrcamento").validate({
        rules:
        {
            nome:{required: true},
            cidade:{required: true},
			email: { required: true, email: true},
			telefone:{required: true},
			mensagem:{required: true},
        },
		messages: {
			nome: { required: 'Informe o seu nome', minlength: 'No mínimo 5 letras' },
			cidade: { required: 'Informe a sua cidade'},
			email: { required: 'Informe o seu e-mail', email: 'E-mail inválido, verifique.'},
			telefone:{ required:  'Informe o seu telefone'},
			mensagem:{ required:  'Informe a mensagem'},
		},
		submitHandler: function( form ){
			var dados = $(form).serialize();

			$.ajax({
				type: "POST",
				url: "envia-orcamento.php",
				data: dados,
				success: function( data )
				{
					//alert(data);
					//Mostra a Msg no topo
					showMessage("success", "Pedido de orçamento enviado com sucesso!");
					goog_report_conversion();

					//Reseta o Form
					$(form).each (function(){
					  this.reset();
					});
				}
			});
		}
    });
}
